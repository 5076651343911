import React from "react"
import { Box, Text } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"
import PageNumber from "../../../components/adventures/PageNumber"

export default function Part03() {
  return (
    <Part number="3" title="The Cavernous Caves" time="20–30 minutes">
      <Section type="Setup">
        <Block type="Secret Context" title="Setup">
          <Text>In this part of the story, you will present the players with a Trial that tests their ability to navigate through a treacherous cave system.</Text>
          <Text>In a Trial, the players take turns to describe how they deal with a situation. They must collectively succeed a number of times equal to the number of members of The Party plus two. (For example, if The Party has 4 players, they must succeed 6 times.) If they fail four times, they fail the Trial.</Text>
        </Block>
      </Section>

      <Section type="Context">
        <Block type="Exposition" title="The Chasm">
          <Text>After a short walk up a tunnel, you gasp. Inside the mountain is a great chamber with chasms deeper than you can see. Glowing stalactites and stalagmites stick out everywhere, like a mouth full of sharp teeth. A maze-like path winds through the chamber to the other side, where you see another large door in the distance.</Text>
        </Block>
      </Section>

      <Section type="Encounter">
        <Block type="Secret Context" title="Trial: The Cavernous Caves">
          <Text>Use the rules for <PageNumber digital="116" physical="155">trials</PageNumber> to run this scene. The players will be challenged to make it to the other side of the caves across a number of obstacles.</Text>
          <Text>Remember that in a Trial, the players can do almost anything to deal with the challenge as long as it makes sense. Use what the players decide to do to help build on the scene. For example, if a player fails a roll and dangles from a ledge, another player can use their turn to roll to see if they can help their friend up.</Text>
          <Text>In a Trial, the players must roll the die during their turn no matter what they decide to do. If they decide to use an ability, you can ignore the typical outcomes for that ability and instead think about how it applies to what the player is trying to do in the Trial.</Text>
        </Block>

        <Block type="Open Context" title="Suggested Challenges">
          <Box as="ul">
            <Text as="li">A stalactite falls from the ceiling, crashing into the path ahead and creating a gap over the chasm.</Text>
            <Text as="li">A flock of angry bats swirls around the party, causing them to lose their balance.</Text>
            <Text as="li">A player steps on a trap that will shoot an arrow at them if they take their foot off of it. (The arrow deals 2 damage.)</Text>
            <Text as="li">The light from the glowing stone fades, making the room dark.</Text>
            <Text as="li">The path becomes slippery from a coating of ice.</Text>
            <Text as="li">A magic fog fills the room, making it difficult to see.</Text>
            <Text as="li">A rickety wooden bridge has planks that begin to fall into the chasm.</Text>
          </Box>
        </Block>

        <Block type="Secret Context" title="Outcomes">
          <Text>If the players pass the Trial, they make it to the other side and can proceed through the door up the mountain. If they fail the trial, have each player roll the die. The player who rolls the lowest number loses their treasure in the crossing.</Text>
        </Block>
      </Section>
    </Part>
  )
}
